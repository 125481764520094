import React from "react";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import Agents from "../Agents";
import AgentImage1 from "../../Assets/Agents/1.png";
import AgentImage2 from "../../Assets/Agents/2.png";
import AgentImage3 from "../../Assets/Agents/3.png";

import "./styles.css";

function AgentsCarousel(props) {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <Carousel
      showDots={props.isMobile ? true : false}
      containerClass="agents-carousel-container"
      responsive={responsive}
      arrows={false}
      autoPlay={props.isMobile ? true : false}
      infinite={props.isMobile ? true : false}
      transitionDuration={500}
      autoPlaySpeed={2500}
      keyBoardControl={true}
    >
      <Agents
        colortheme={"rgb(133, 224, 105)"}
        agentName="Navneet Singh"
        agentImage={AgentImage1}
        agentFeedback='"Innvocon has come up with a solution that can bring about a revolutionary change in learning and development and recruitment methodologies. with much effective lean set of services which can bridge skill gap in the new age of graduates."'
      />
      <Agents
        colortheme={"rgb(114, 157, 236)"}
        agentName="Aakarsh Naidu"
        agentImage={AgentImage2}
        agentFeedback='"Innvocon is a great product with win win situation for colleges, students and companies. The capabilities of the product to provide the hands on project based learning are matched with equally good expert mentorship."'
        designation="Founder & CEO, Startupreneur"
      />
      <Agents
        colortheme={"red"}
        agentName="Chaitra Satayanarayana"
        agentImage={AgentImage3}
        agentFeedback='"Innvocon has been excellent in supporting our organization. So far they are invaluable to us when we were trying to achieve our target in bringing a pool of potential candidates onboard."'
        designation="HR Manager, Benchmark Digital Solutions"
      />
      {/* <Agents colortheme={"red"} /> */}
    </Carousel>
  );
}

export default AgentsCarousel;
