import React from "react";
import "./styles.css";

function Mentors(props) {
  return (
    <div className="mentor-container">
      <img className="mentor-img" src={props.Image} alt="Mentor" />
      <div className="mentor-description">
        <p style={{ fontSize: "1.2rem" }}>{props.name}</p>
        <p>
          {props.designation} {props.college && `| ${props.college}`}
        </p>
      </div>
    </div>
  );
}

export default Mentors;
