import React from "react";
import "./styles.css";

function SideMenu(props) {
  return (
    <div className="side-menu-parent">
      <div className="side-menu"></div>
    </div>
  );
}

export default SideMenu;
