import React from "react";
import CustomCheckbox from "../CustomCheckbox";
import "./styles.css";
// import img1 from "../../Assets/image122.png";

function Courses(props) {
  return (
    <div className="course">
      <div className="image-container">
        <img className="images" alt="none" src={props.image} />
      </div>
      <div className="content-container">
        <p style={{ fontSize: "1.11rem" }}>{props.courseName}</p>
        <div className="course-level">
          <div className="level-box">
            <div
              style={{
                borderRadius: "20px",
                height: "inherit",
                width: `${props.levelNum * 80}px`,
                backgroundColor: "rgb(57, 116, 226)",
              }}
            ></div>
          </div>
        </div>
        <div className="course-type">
          <CustomCheckbox label="Foundational" />
          <CustomCheckbox label="Advanced" />
        </div>
        {/* <p style={{ fontSize: "1.2rem" }}>₹ {props.price}</p> */}
       
      </div>
    </div>
  );
}

export default Courses;
