import React from "react";
import "./styles.css";

function Services(props) {
  return (
    <div
      className="services-container"
      style={{ borderColor: `${props.colortheme}` }}
    >
      <img src={props.image} alt="none" />
      <p>{props.heading}</p>
      <p>{props.details}</p>
    </div>
  );
}

export default Services;
