import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import partner1 from "../../Assets/partners/1.png";
import partner2 from "../../Assets/partners/2.png";
import partner3 from "../../Assets/partners/3.png";
import partner4 from "../../Assets/partners/4.png";
import partner5 from "../../Assets/partners/5.png";
import partner6 from "../../Assets/partners/6.png";
import partner7 from "../../Assets/partners/7.png";
import partner8 from "../../Assets/partners/8.png";
import partner9 from "../../Assets/partners/9.png";
// import partner10 from "../../Assets/partners/10.png";
import partner11 from "../../Assets/partners/11.png";
// import partner12 from "../../Assets/partners/12.png";
import partner13 from "../../Assets/partners/13.png";
import partner14 from "../../Assets/partners/14.png";
import partner15 from "../../Assets/partners/15.png";
import partner16 from "../../Assets/partners/16.png";

import "./styles.css";

function PartnersCarousel() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };

  // function CustomRightArrow({ onClick }) {
  //   return (
  //     <button
  //       onClick={onClick}
  //       aria-label="Go to next slide"
  //       className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
  //     />
  //   );
  // }

  // function CustomLeftArrow({ onClick }) {
  //   return (
  //     <button
  //       onClick={onClick}
  //       aria-label="Go to previous slide"
  //       className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
  //     />
  //   );
  // }

  // const ButtonGroup = ({ next, previous }) => {
  //   return (
  //     <div className="carousel-button-group">
  //       <CustomLeftArrow onClick={() => previous()} />
  //       <CustomRightArrow onClick={() => next()} />
  //     </div>
  //   );
  // };
  return (
    <Carousel
      containerClass="carousel-container-partners"
      responsive={responsive}
      itemClass="carousel-item"
      // customLeftArrow={<CustomLeftArrow />}
      // customRightArrow={<CustomRightArrow />}
      // renderButtonGroupOutside={true}
      // customButtonGroup={<ButtonGroup />}
      // showDots={true}
      arrows={false}
      autoPlay={true}
      infinite={true}
      transitionDuration={500}
      autoPlaySpeed={1000}
      keyBoardControl={false}
    >
      <div className="partner-img-container">
        <img className="partner-img" src={partner1} alt="partner" />
      </div>
      <div className="partner-img-container">
        <img className="partner-img" src={partner2} alt="partner" />
      </div>
      <div className="partner-img-container">
        <img className="partner-img" src={partner3} alt="partner" />
      </div>
      <div className="partner-img-container">
        <img className="partner-img" src={partner4} alt="partner" />
      </div>
      <div className="partner-img-container">
        <img className="partner-img" src={partner5} alt="partner" />
      </div>
      <div className="partner-img-container exception">
        <img className="partner-img" src={partner6} alt="partner" />
      </div>
      <div className="partner-img-container">
        <img className="partner-img" src={partner7} alt="partner" />
      </div>
      <div className="partner-img-container">
        <img className="partner-img" src={partner8} alt="partner" />
      </div>
      <div className="partner-img-container">
        <img className="partner-img" src={partner9} alt="partner" />
      </div>
      {/* <div className="partner-img-container">
        <img className="partner-img" src={partner10} alt="partner" /> //solar labs
      </div> */}
      <div className="partner-img-container">
        <img className="partner-img" src={partner11} alt="partner" />
      </div>
      {/* <div className="partner-img-container">
        <img className="partner-img" src={partner12} alt="partner" />
      </div> */}
      <div className="partner-img-container exception">
        <img className="partner-img" src={partner13} alt="partner" />
      </div>
      <div className="partner-img-container exception">
        <img className="partner-img" src={partner14} alt="partner" />
      </div>
      <div className="partner-img-container exception">
        <img className="partner-img" src={partner15} alt="partner" />
      </div>
      <div className="partner-img-container">
        <img className="partner-img" src={partner16} alt="partner" />
      </div>
    </Carousel>
  );
}

export default PartnersCarousel;
