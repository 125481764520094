import React from "react";
import "./styles.css";

import Courses from "../../Components/Courses";
import Services from "../../Components/Services";
import MentorsCarousel from "../../Components/MentorsCarousel";
import AgentsCarousel from "../../Components/AgentsCarousel";

import landingImage from "../../Assets/landing.jpg";
import subImage from "../../Assets/subimage.png";

import incubImage1 from "../../Assets/partners/3.png";
import incubImage2 from "../../Assets/partners/5.png";

import CourseImage1 from "../../Assets/course1.jpg";
import CourseImage2 from "../../Assets/course2.jpg";
import CourseImage3 from "../../Assets/course3.png";
import CourseImage4 from "../../Assets/course4.jpg";

import ServiceImage1 from "../../Assets/Capture1.png";
import ServiceImage2 from "../../Assets/Capture2.png";
import ServiceImage3 from "../../Assets/Capture3.png";
import ServiceImage4 from "../../Assets/Capture4.png";
// import partner1 from "../../Assets/serviceimg1.png";
import PartnersCarousel from "../../Components/PartnersCarousel";

function Home(props) {
  return (
    <div className="home-parent">
      <div className="home">
        {!props.isMobile && <img src={landingImage} alt="" />}
        <div className="inner-div">
          <ul className="list">
            <li>
              <h4>Learn.</h4>
            </li>
            <li>
              <h4>Practise.</h4>
            </li>
            <li>
              <h4>Get Hired.</h4>
            </li>
            <li>
              <h4></h4>
            </li>
            <li>
              <p>
                Learn and Work on real-time Industry projects and become
                job-ready for careers of the futures.
              </p>
            </li>
           
          </ul>
        </div>
      </div>
      <div id="aboutus" className="home-view2">
        {!props.isMobile && (
          <div className="home-view2-images">
            <img className="subimage" src={subImage} alt="" />
          </div>
        )}
        <div className="home-view2-content">
          <p>World-Class Industry Oriented Learning for you</p>
          <p>
            The Workplace Learning Program, a truly industry driven learning
            ecosystem provides the best in-class practical learning with top
            industry experts and job support.
          </p>
          {/* <button className="home-view2-button">Get Started</button> */}
        </div>
      </div>
      <div className="home-view3">
        <div className="home-view3-content">
          <p>Explore the Workplace Learning Program and plan your career</p>
          <p>
            Industry Experts from top technology complanies teach learners on
            innvocon.
          </p>
        </div>
        <div className="course-list">
          <Courses
            image={CourseImage1}
            courseName="Full Stack Web Developer"
            level="Advanced"
            price="4999"
            levelNum={0.8}
          />
          <Courses
            image={CourseImage2}
            courseName="UI/UX Designer"
            level="Advanced"
            price="4999"
            levelNum={0.8}
          />
          <Courses
            image={CourseImage3}
            courseName="Data Science"
            level="Intermediate"
            price="4999"
            levelNum={0.5}
          />
          <Courses
            image={CourseImage4}
            courseName="Data analyst"
            level="Intermediate"
            price="4999"
            levelNum={0.5}
          />
        </div>
      </div>
      <div id="services" className="home-view4">
        <div className="home-view4-content">
          <p>Learn in-demand skills and work with leading tech companies</p>
          <p>
            Why Innvocon has answers to all your career and skill related
            queries
          </p>
        </div>
        <div className="services-list">
          <Services
            image={ServiceImage1}
            colortheme={"rgb(133, 224, 105)"}
            heading="Get In-demand skills"
            details="Our quality project-based courses are designed with top industry experts,so you learn the in-demand skills that top corporate companies want."
          />
          <Services
            colortheme={"rgb(114, 157, 236)"}
            image={ServiceImage2}
            heading="Project based Learning"
            details="Learn by doing with real time industry projectsthat lead to experience in jobs of the future."
          />
          <Services
            colortheme={"red"}
            image={ServiceImage3}
            heading="Industry Experts"
            details="Our highly skilled mentors from top technology companies are here to help you learn and solve your queries."
          />
          <Services
            colortheme={"rgb(240, 230, 93)"}
            image={ServiceImage4}
            heading="Workplace Learning"
            details="Not just another training program Learn while you work on real industry projects from top mentors in a real company like work environment."
          />
        </div>
      </div>
      <div className="home-view5">
        <div className="home-view5-content">
          <div className="home-view5-text">
            <p>Smart Hiring by Innvocon</p>
            <p>
              Get world-class training and hiring solutions developed by
              Industry Pioneers. All on Smart hiring by Innvocon
            </p>
          </div>
        </div>
        <div
          className="home-view5-clients"
          style={{ padding: "50px 25px 50px 25px" }}
        >
          <AgentsCarousel isMobile={props.isMobile} />
          {/* <Agents colortheme={"rgb(133, 224, 105)"} />
          <Agents colortheme={"rgb(114, 157, 236)"} />
          <Agents colortheme={"red"} /> */}
        </div>
      </div>
      <div className="home-view6">
        <p className="mentors-title">The Mentors</p>
        <div className="mentors-carousel">
          <MentorsCarousel />
        </div>
      </div>
      <div className="home-view7">
        <p className="partners-title">Universities and Partners</p>
        <div className="partners-carousel">
          <PartnersCarousel />
          {/* <img src={partner1} alt="partner" className="partner-logo" /> */}
          {/* <img src={partner1} alt="partner" className="partner-logo" /> */}
          {/* <img src={partner1} alt="partner" className="partner-logo" /> */}
          {/* <img src={partner1} alt="partner" className="partner-logo" /> */}
          {/* <img src={partner1} alt="partner" className="partner-logo" /> */}
        </div>
      </div>
    </div>
  );
}

export default Home;
