import React from "react";
import "./styles.css";

import Agent from "../../Assets/agent1.png";

function Agents(props) {
  return (
    <div
      className="agents-container"
      style={{ borderColor: `${props.colortheme}` }}
    >
      <img
        className="agent-image"
        src={props.agentImage ? props.agentImage : Agent}
        alt="hiring client"
      />
      <p className="agent-name">
        {props.agentName ? props.agentName : "Prateek Verma"}
      </p>
      <p className="agent-feedback">
        {props.agentFeedback
          ? props.agentFeedback
          : '"Our quality project-based courses are designed with top industry experts. so you learn the in demand skills that top compnaies want"'}
      </p>
      <p className="agent-designation">
        {props.designation
          ? props.designation
          : "Reliance Retail | DGM | IIM A"}
      </p>
    </div>
  );
}

export default Agents;
