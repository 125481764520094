import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import Mentors from "../Mentors";
// import mentorimg from "../../Assets/agent1.png";

import mentor1 from "../../Assets/mentors/1.png";
import mentor2 from "../../Assets/mentors/2.png";
import mentor3 from "../../Assets/mentors/3.png";
import mentor4 from "../../Assets/mentors/4.png";
import mentor5 from "../../Assets/mentors/5.png";
import mentor6 from "../../Assets/mentors/6.png";
import mentor7 from "../../Assets/mentors/7.png";
import mentor8 from "../../Assets/mentors/8.png";
// import mentor9 from "../../Assets/mentors/9.png";

import "./styles.css";
function MentorsCarousel() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <Carousel
      showDots={true}
      containerClass="carousel-container"
      responsive={responsive}
      arrows={false}
      // dotListClass="custom-dot-list-style"
      // itemClass="carousel-item-padding-40-px"
      removeArrowOnDeviceType={["tablet", "mobile"]}
      autoPlay={true}
      infinite={true}
      transitionDuration={500}
      autoPlaySpeed={2000}
      keyBoardControl={true}
    >
      <Mentors
        Image={mentor1}
        name="Navneet Singh"
        designation="DGM Reliance"
        college="IIM Ahmedabad"
      />
      <Mentors
        Image={mentor2}
        name="Ajeet Gill"
        designation="Co-founder & Chief Designer MeetScribe"
      />
      <Mentors
        Image={mentor3}
        name="Akash Gaur"
        designation="SDE-II, MPL"
        college="MNIT Allahabad"
      />
      <Mentors
        Image={mentor4}
        name="Praveen Kumar"
        designation="SDE Cisco"
        college="BITS Pilani"
      />
      <Mentors
        Image={mentor5}
        name="Nooral Hasan"
        designation="SDE Oracle Corporation"
      />
      <Mentors Image={mentor6} name="Akhil Batra" designation="SDE Unacademy" />
      <Mentors
        Image={mentor7}
        name="Deepti Rajpoot"
        designation="Growth & Strategy OYO Rooms"
        college="IIT Kanpur"
      />
      <Mentors
        Image={mentor8}
        name="Vaibhav Kumar"
        designation="Sr. Manager, L&T Inc."
        college="MNNIT Allahabad"
      />
      {/* <Mentors
        Image={mentor9}
        name="Akash Gaur"
        designation="SDE-II, MPL"
        college="MNIT Allahabad"
      /> */}
    </Carousel>
  );
}

export default MentorsCarousel;
