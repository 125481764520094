import React from "react";
import checkmark from "../../Assets/checkmark.ico";
import "./styles.css";

function CustomCheckbox(props) {
  return (
    <div className="checkbox-container">
      <div className="check">
        <img src={checkmark} alt="" />
      </div>
      <p>{props.label ? props.label : "nothing"}</p>
    </div>
  );
}

export default CustomCheckbox;

//TODO ipad responsiveness to be fixed
