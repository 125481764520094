import React from "react";
// import { Squash as Hamburger } from "hamburger-react";
import { Link } from "react-scroll";
import "./styles.css";

import logo from "../../Assets/innvocon.png";

function Header(props) {
  return (
    <header id="home" className="header">
      <img src={logo} className="logo" alt="" />
      {!props.isMobile && (
        <div className="menu">
          <div className="menu-items">
            <div className="menu-item">
              <Link to="aboutus" spy={true} smooth={true}>
                <p>About Us</p>
              </Link>
            </div>
            <div className="menu-item">
              <Link to="services" spy={true} smooth={true}>
                <p>Services</p>
              </Link>
            </div>
            <div
              onClick={() => {
                window.location.href = "https://rzp.io/l/BZS7KZHHi8";
              }}
              className="menu-item"
            >
              <p>Enroll Now</p>
            </div>
            <div className="menu-item">
              <Link to="contactus" spy={true} smooth={true}>
                <p>Contact</p>
              </Link>
            </div>
          </div>
        </div>
      )}
    </header>
  );
}

export default Header;
