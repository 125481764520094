import React from "react";
import { Link } from "react-scroll";

import "./styles.css";

function Footer(props) {
  return (
    <div id="contactus" className="footer-container">
      <div className="footer-intro">
        <p>Innvocon</p>
        <p>
          The Workplace Learning Program, a truly industry driven learning
          ecosystem provides the best in-class practical learning with top
          industry experts and job support.
        </p>
      </div>
      {!props.isMobile && (
        <div className="footer-more">
          <p>MORE</p>
          <div>
            <Link to="home" spy={true} smooth={true}>
              <p>Home</p>
            </Link>
            <Link to="aboutus" spy={true} smooth={true}>
              <p>About us</p>
            </Link>
            <Link to="contactus" spy={true} smooth={true}>
              <p>Contact us</p>
            </Link>
          </div>
        </div>
      )}
      <div className="footer-contact">
        <p>CONTACT US</p>
        <p style={{ fontWeight: "400" }}>Address:</p>
        <p>
          502, 5th floor, Seminar Block, IIIT Delhi, Okhla Industrial Area, Phase 3, New Delhi- 110020
        </p>
        <p>Mobile: +917983188057</p>
        <p>Email: team@innvocon.co.in</p>
      </div>
      {props.isMobile && (
        <div className="footer-more-mobile">
          <Link to="home" spy={true} smooth={true}>
            <p>Home</p>
          </Link>
          <Link to="aboutus" spy={true} smooth={true}>
            <p>About us</p>
          </Link>
          <Link to="contactus" spy={true} smooth={true}>
            <p>Contact us</p>
          </Link>
        </div>
      )}
    </div>
  );
}

export default Footer;
