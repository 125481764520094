import { useState, useEffect } from "react";
import "./App.css";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import SideMenu from "./Components/SideMenu";
import Home from "./Views/Home";

function App() {
  const [isMobile, setIsMobile] = useState(false);
  const [sidemenu, setSidemenu] = useState(false);
  function sideMenutoggle() {
    setSidemenu(!sidemenu);
    console.log("side menu toggle");
  }

  useEffect(() => {
    console.log("mount app");

    let updateWidth = () => {
      window.innerWidth < 700 ? setIsMobile(true) : setIsMobile(false);
      console.log("update window");
    };

    updateWidth();

    window.addEventListener("resize", updateWidth);

    return () => {
      console.log("unmount");
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  return (
    <div className="App">
      <Header
        isMobile={isMobile}
        sidemenu={sidemenu}
        toggleSideMenu={sideMenutoggle}
      />
      {isMobile && sidemenu && (
        <SideMenu inView={sidemenu} toggleSideMenu={sideMenutoggle} />
      )}
      <Home isMobile={isMobile} />
      <Footer isMobile={isMobile} />
    </div>
  );
}

export default App;
